import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeTwo from "./pages/HomeTwo";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import AboutUs from "./components/Pages/About";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import ToS from "./components/Pages/ToS";
import ContactUs from "./components/Pages/contactUs";
import Hotel from "./components/Dashboard/investor";
import Investor from "./components/Dashboard/hotel";
import Login from "./components/Dashboard/login";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomeTwo />} />
        <Route exact path='/About' element={<AboutUs/>}/>
        <Route exact path='/PrivacyPolicy' element={<PrivacyPolicy />} />
        <Route exact path='/ToS' element={<ToS/>} />
        <Route exact path='/contact' element={<ContactUs/>} />
        {/* <Route exact path='/investor' element={<Hotel/>} /> */}
        {/* <Route exact path='/hotel' element={<Investor/>} /> */}
        <Route exact path='/login' element={<Login/>} />
      </Routes>
      <ScrollToTop smooth color='#246BFD' />
    </BrowserRouter>
  );
}

export default App;
