import React, { useState } from "react";
import { Link } from "react-router-dom";

const NavbarTwo = () => {
  const [active, setActive] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };


  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  return (
    <>
      {/* ==================== Navbar Two Start ====================*/}
      <nav className="navbar navbar-area navbar-area-2 navbar-expand-lg">
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className='Title'>
          <h1
                  className="title"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                  style={{fontSize:"2rem", fontWeight:"700" }}
                >
                  <span style={{color:"#682074"}}>JOY </span>STAYS <p style={{fontSize:"15px"}}>A Unit of JOX Private Limited</p>
                </h1>
          </div>
          <div className="nav-right-part nav-right-part-mobile">
            {/* <span className="search-bar-btn" onClick={searchActive}>
              <FaSearch />
            </span> */}
          </div>
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-end" style={{display: "flex", justifyContent:"flex-end"}}>
              <li className="menu-item-has-children">
                <Link to="/About">
                  <a href="#">About us</a>
                </Link>
                <ul className="sub-menu"></ul>
              </li>
              <li className="menu-item-has-children">
                <Link to="/Contact">
                  <a href="#">Contact us</a>
                </Link>
                <ul className="sub-menu">
                  <li>{/* <Link to='/service'>Service</Link> */}</li>
                  <li>
                    {/* <Link to='/service-details'>Service Single</Link> */}
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link to="/PrivacyPolicy">
                  <a href="#">Privacy Policy</a>
                </Link>
                <ul className="sub-menu">
                  <li>{/* <Link to='/about'>About Us</Link> */}</li>
                  <li>{/* <Link to='/team'>Team</Link> */}</li>
                  <li>{/* <Link to='/team-details'>Team Details</Link> */}</li>
                  <li>
                    {/* <Link to='/case-study-details'>Case Study Details</Link> */}
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link to='/ToS'>
                  <a href="#">Terms & Conditions</a>
                </Link> 
              </li>
            </ul>
          </div>
          {/* <div className="nav-right-part nav-right-part-desktop align-self-center">
            <Link className="btn btn-border-base" to="/about">
              About Us <FaPlus />
            </Link>
          </div> */}
        </div>
      </nav>
      {/* ==================== Navbar Two end ====================*/}
    </>
  );
};

export default NavbarTwo;

