import React, { useState } from 'react';
import {
  TextField,
  Button,
  TextareaAutosize,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 600,
    padding: theme.spacing(2),
    border: '2px solid #000000',
    borderRadius: '12px',
    boxShadow: theme.shadows[1],
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  messageContainer: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    resize: 'none',
    fontSize: theme.typography.body1.fontSize,
    '&:focus': {
      outline: 'none',
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    backgroundColor: '#000',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111',
    },
  },
}));

const ContactUs = () => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submissionMessage, setSubmissionMessage] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("https://formcarry.com/s/u9hdSFbIGI", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        message: formData.message,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 200) {
          setSubmissionMessage('Thank you for contacting us!');
          setFormData({
            name: '',
            email: '',
            message: '',
          });
        } else {
          setError(res.message);
        }
      })
      .catch((error) => setError(error));
  };

  return (
    <Container className={classes.container}>
      <div className={classes.formContainer}>
        <Typography variant="h4" align="center" className={classes.title}>
          Contact Us
        </Typography>
        {submissionMessage && (
          <Typography variant="body1" color="primary">
            {submissionMessage}
          </Typography>
        )}
        {error && (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={classes.inputField}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={classes.inputField}
          />
          <TextareaAutosize
            aria-label="message"
            rowsMin={5}
            placeholder="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className={classes.messageContainer}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            className={classes.submitButton}
          >
            Submit
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default ContactUs;
