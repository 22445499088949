import React from 'react';

const AboutUs = () => (
  <div
    style={{
      fontFamily: 'Poppins, sans-serif',
      textAlign: 'justify',
      padding: '20px',
      maxWidth: '800px',
      margin: 'auto',
      lineHeight: '1.5',
    }}
  >
    <h1
      style={{
        fontSize: '4em',
        marginBottom: '3rem',
        marginTop: '3rem',
      }}
    >
      About Us
    </h1>

    <section>
      <p>
        Welcome to Joy Stays, where innovation meets hospitality! Established in 2016 by the visionary Mr. Randhir Thakur, a seasoned Investment banker renowned for managing wealth for prominent High Net Worth Individuals (HNIs), Joy Stays is proud to be India's first hotel room trading app.
      </p>
    </section>

    <section>
      <h2 style={{ fontSize: '1.5em', marginBottom: '2rem', marginTop: '2rem'}}>Our Approach</h2>

      <p>
        At Joy Stays, we've redefined the way hotels operate and thrive in the competitive market. Our groundbreaking concept involves pre-purchasing rooms in advance from our esteemed hotel partners for the entire year. This unique approach not only empowers our partners to effectively manage their cash flow requirements but also opens doors to additional profits during peak booking seasons.
      </p>

      <p>
        With an initial presence in the scenic landscapes of Goa, boasting over 750 rooms, we are poised for expansion across the northern, western, and southern regions of India. Our ambitious goal is to establish a significant footprint in major tourist and leisure destinations, crafting a network of 2500+ rooms by the end of 2024.
      </p>
    </section>

    <section>
      <h2 style={{ fontSize: '1.5em', marginBottom: '2rem', marginTop: '2rem' }}>Meet Our Founders</h2>

      <p>
        Meet the driving force behind Joy Stays - Mr. Randhir Thakur: With a background deeply rooted in investment banking and a proven track record of managing wealth for some of the most discerning HNIs, Mr. Thakur brings a wealth of expertise to the hospitality industry. His vision for Joy Stays is not just about creating a trading platform but revolutionizing the way hotels operate, ensuring sustained profitability and providing an unparalleled experience for both partners and guests.
      </p>

      <p>
        Adding an extra layer of excellence to our leadership is Ms. Vibha, the co-founder with a rich background in hospitality. With extensive experience in managing revenue and ensuring amazing guest experiences, Ms. Vibha Parmar is the heartbeat of Joy Stays. Her profound understanding of the industry intricacies complements Mr. Thakur's financial acumen, creating a dynamic leadership duo that steers Joy Stays towards unprecedented success.
      </p>
    </section>

    <section>
      <h2 style={{ fontSize: '1.5em',marginBottom: '2rem', marginTop: '2rem' }}>Our Mission</h2>

      <p>
        Joy Stays is not just a platform; it's a mission. Our super enthusiastic and dynamic team is dedicated to creating value for our hotel partners, paving the way for enhanced facilities and delivering delightful experiences to guests nationwide. Through our strategic expansion plans, we aim to redefine the hospitality landscape, one room at a time.
      </p>
    </section>

    <section>
      <h2 style={{ fontSize: '1.5em', marginBottom: '2rem', marginTop: '2rem' }}>Why Choose Joy Stays</h2>

      <ul>
        <li>
          <strong>Innovative Approach:</strong> We bring a fresh perspective to the hospitality industry, offering a novel solution that benefits both hotels and guests.
        </li>
        <li>
          <strong>Strategic Expansion:</strong> With a stronghold in Goa, we are expanding rapidly to become a household name in tourist and leisure destinations across India.
        </li>
        <li>
          <strong>Proven Leadership:</strong> Led by Mr. Randhir Thakur and Ms. Vibha Parmar, our leadership team brings a powerful combination of financial expertise and hospitality experience to the table.
        </li>
        <li>
          <strong>Guest-Centric Philosophy:</strong> Joy Stays is not just about transactions; it's about creating memorable experiences. We are committed to providing guests with unparalleled experiences at our partner hotels.
        </li>
      </ul>
    </section>

    <section>
      <h2 style={{ fontSize: '1.5em', marginBottom: '2rem', marginTop: '2rem' }}>Join Us</h2>

      <p>
        Join us on this exciting journey as we redefine hospitality and create a legacy of Joyous stays. Your comfort is our business, and your satisfaction is our success. Welcome to Joy Stays - Where Every Stay is a Celebration!
      </p>
    </section>
  </div>
);

export default AboutUs;
