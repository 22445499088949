// Login.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Login({ route }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (route === 'hotel' || route === 'investor') {
      // Add your logic for verifying OTP here
      navigate(`/${route}`);
    } else {
      console.log('Please select Hotel or Investor');
    }
  };

  const styles = {
    loginContainer: {
      backgroundColor: '#441b77',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    },
    title: {
      color: '#fff',
      marginBottom: '20px',
    },
    input: {
      width: '100%',
      padding: '10px',
      marginBottom: '10px',
      borderRadius: '5px',
      border: 'none',
      outline: 'none',
    },
    button: {
      width: '100%',
      padding: '10px',
      marginTop: '10px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontWeight: 'bold',
      transition: 'background-color 0.3s',
      backgroundColor: '#673AB7',
      color: '#fff',
    },
  };

  return (
    <div style={styles.loginContainer}>
      <h2 style={styles.title}>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="tel"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          style={styles.input}
          required
        />
        <input
          type="text"
          placeholder="OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          style={styles.input}
          required
        />
        <button type="button" style={styles.button}>
          Request OTP
        </button>
        <button type="submit" style={styles.button}>
          Verify OTP
        </button>
      </form>
    </div>
  );
}

export default Login;