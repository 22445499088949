import React, { useState, useEffect } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const BlogAreaTwo = () => {
  const [expanded, setExpanded] = useState([false, false, false, false]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    // Initial check on mount
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleExpanded = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const scrollableContentStyle = {
    maxHeight: "150px",
    overflowY: "auto",
    paddingRight: "5px",
    marginBottom: "10px",
  };

  const closeIconStyle = {
    cursor: "pointer",
    position: "absolute",
    right: "10px",
    top: "10px",
  };

  const cardDetailsStyle = {
    position: "relative",
    height: isMobile ? "auto" : "250px", 
  };

  const expandedContentStyle = {
    maxHeight: "150px", 
    overflowY: "auto",
    marginBottom: "10px",
  };

  const roleTextStyle = {
    fontSize: isMobile ? "14px" : "16px",
  };

  const teamMembers = [
    {
      name: "Randhir",
      image: "assets/img/blog/Randhir.png",
      role: "Visionary Founder and CEO of Joy Stays.",
      content: `
        With an MBA from the esteemed Symbiosis Institute of Management Studies, Randhir brings over 14 years of profound expertise as a seasoned investment banker. 
        His illustrious career includes pivotal roles at renowned financial institutions such as Tata Capital, Yes Bank, and Indusind Bank, where he collaborated closely with High Net Worth Individuals (HNI) and Ultra High Net Worth Individuals (UHNI) clients.
        Renowned for his specialization in wealth advisory and adept management of large-scale projects, Randhir is not only a financial maestro but also a dynamic entrepreneur.
        His dual passion for finance and the hospitality industry is evident in his successful management of hotels for the past six years. Randhir's leadership philosophy is one of leading by example,
        serving as a growth catalyst for both the organization and its dedicated team members. With a keen eye for innovation and a commitment to excellence, Randhir propels Joy Stays towards unparalleled success in the dynamic landscape of business and hospitality.
      `,
    },
    {
      name: "Vibha",
      image: "assets/img/blog/Vibha.png",
      role: "Dynamic Co-founder of Joy Stays.",
      content: `
        Vibha's journey in the hospitality industry has been nothing short of extraordinary. Armed with a diploma in Aviation and Hospitality, Vibha boasts an impressive 12+ years of experience, making her a seasoned expert in the field.
        Her passion for the industry extends beyond the conventional, having successfully navigated the intricate landscapes of both established hospitality giants and nimble start-ups.
        A true maven in sales, operations, and team management, Vibha is the linchpin of Joy Stays' robust systems and processes.
        Behind the scenes, she orchestrates seamless operations, ensuring that every detail aligns with Joy Stays' commitment to excellence.
        Beyond her professional prowess, Vibha's zest for life takes her to the far corners of the globe. A fervent traveler, she finds Joy in exploring diverse cultures and discovering hidden gems.
        With her vision and unwavering commitment, Vibha embodies the spirit of Joy Stays, where every detail is crafted with precision and every guest experience is a testament to her passion for hospitality.
      `,
    },
    // {
    //   name: "Rohan",
    //   image: "assets/img/blog/Rohan.png",
    //   role: "Driving force behind Joy STAYS as Chief Revenue & Investment Officer.",
    //   content: `
    //     With a Bachelor's in Hospitality from IHM Bangalore and an MBA in Strategy & Leadership from ISB Hyderabad, Rohan brings a wealth of expertise cultivated over a decade in renowned global hotel chains such as Marriott, Accor, and the Shangri-La Group.
    //     Rohan's professional journey is marked by a relentless commitment to growth and strategy, having successfully led operations, business units, and teams with a keen focus on revenue optimization and exceptional customer service.
    //     A seasoned mentor, he has steered teams toward revenue excellence, fostered C-suite relationships, and achieved unparalleled levels of customer satisfaction and retention.
    //     Beyond the boardroom, Rohan's passion lies in solving business challenges to streamline costs and foster efficient business growth.
    //     A wealth-growing aficionado, he extends his expertise to peers, helping them navigate and enhance their financial portfolios.
    //     With a proven track record in scaling early-stage ventures, Rohan embodies the essence of financial stewardship, propelling Joy Stays to new heights with his strategic vision and business acumen.
    //   `,
    // },
    // {
    //   name: "Ajay",
    //   image: "assets/img/blog/Ajay.png",
    //   role: "The dynamic Chief Marketing and Growth Officer at Joy Stays.",
    //   content: `
    //     Ajay's professional journey spans over a decade of unparalleled success and innovation. Armed with an MBA in Marketing and HR from Devi Ahilya Vishwavidyalaya, Indore, Ajay is a visionary leader with a track record of transforming high-growth companies.
    //     Ajay's impressive career includes impactful roles at esteemed organizations such as Asian Paints, Akzonobel India, and Reliance Retail, along with pivotal contributions to the growth stories of disruptive startups like OYO Rooms and Stanza Living.
    //     His versatility shines through as he effortlessly navigates both the realms of traditional businesses and the fast-paced environments of contemporary startups.
    //     With a rich blend of experience in Marketing, Sales, Business Development, and Growth functions, Ajay emerges as a go-getter and result-oriented professional.
    //     His strategic prowess in defining growth strategies and driving business success is a testament to his unwavering commitment to excellence.
    //     Ajay is not just a leader; he's a skilled team builder and manager, fostering an environment where innovation thrives and results flourish.
    //     Beyond the boardroom, Ajay finds Joy in road trips, culinary adventures, and the world of poetry. His multifaceted interests mirror his ability to approach challenges with creativity and passion.
    //     At Joy Stays, Ajay stands at the helm of driving growth and marketing initiatives, embodying the spirit of innovation and success that defines the company's journey into the future.
    //   `,
    // },
  ];


  return (
    <>
      <div className="blog-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center">
                <h6 className="sub-title">Our Team</h6>
                <h2 className="title">The Visionary Minds Behind JOY STAYS</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {teamMembers.map((member, index) => (
              <div key={index} className={`col-lg-6 col-md-6 col-6 ${isMobile ? 'mobile-view' : ''}`}>
                <div className="single-blog-list style-2">
                  <div className="thumb">
                    <img src={member.image} alt={member.name} />
                  </div>
                  <div className="details" style={cardDetailsStyle}>
                    <ul className="blog-meta">
                      <li>
                        <h2 style={{ fontWeight: "400", fontSize:"2rem" }}>{member.name}</h2>
                      </li>
                      {/* Add other list items as needed */}
                    </ul>
                    <h5 className={`mb-3${expanded[index] ? " hidden" : ""}`}>
                      <Link to="#" onClick={() => toggleExpanded(index)} style={roleTextStyle}>
                        {isMobile ? member.role.toLowerCase() : member.role}
                      </Link>
                    </h5>
                    {expanded[index] && (
                      <div
                        className="expanded-content"
                        style={expandedContentStyle}
                      >
                        <div
                          className="close-icon"
                          onClick={() => toggleExpanded(index)}
                          style={closeIconStyle}
                        >
                          <FaTimes />
                        </div>
                        <div
                          className="scrollable-content"
                          style={scrollableContentStyle}
                        >
                          <p className="dummy-text">{member.content}</p>
                        </div>
                      </div>
                    )}
                    <Link
                      className="read-more-text"
                      to="#"
                      onClick={() => toggleExpanded(index)}
                    >
                      <span style={{ paddingBottom: "15px" }}>
                        {expanded[index] ? "Read Less" : "Read More"} <FaPlus />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogAreaTwo;
