import React from "react";
import { FaStar } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialTwo = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 9000, // Adjust the speed for a slow continuous animation
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear", // Continuous leftward animation
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true, // Enable swipe to slide
    responsive: [
      {
        breakpoint: 768, // Breakpoint for mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          speed: 9000, // Adjust the speed for a smoother swipe on mobile
        },
      },
    ],
  };

  const testimonials = [
    {
      content:
        "Investing in Joy Stays has really helped me plan my finances well. Being from a middle-class background, the assured returns and on-time payouts on the app really help me to manage my requirements well.",
      author: "Bhavna Prasad, Mumbai",
    },
    {
      content:
        "I started investing with a small amount first, to see how things work. I was quite amazed at how Joy stays managed to give me the best returns, more than most of the investing ways present in the market. I am a big fan now and definitely planning to multiply my wealth with Joy.",
      author: "Sharvari Kulkarni, Pune",
    },
    {
      content:
        "I trade in the stock market along with my regular job. But never saw such crazy and assured returns which Joy Stays app has been giving me since last 6 months. I like the smooth investing process and timely payments.",
      author: "Rahul Chourasia, Singrauli",
    },
    {
      content:
        "I like the peace of mind that comes as a Joy Stays investor. The best part is that I know my returns with the dates, in advance. This enables me to plan my future expenses and investments well.",
      author: "Manish, New Delhi",
    },
  ];

  return (
    <div className="testimonial-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center">
              <h6 className="sub-title" style={{ color: "#000000" }}>
                TESTIMONIALS
              </h6>
              <h2 className="title">
                Hear the Success Stories from <span> Our Valued </span>Investors
              </h2>
            </div>
          </div>
        </div>
        <div className="testimonial-slider-2">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="item">
                <div className="single-testimonial-inner">
                  <div className="row">
                    <div className="col-12">
                      <div className="icon mb-2">
                        <img src="assets/img/icon/25.png" alt="img" />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <p className="designation mb-0">
                        {testimonial.content}
                      </p>
                    </div>
                    <div className="col-sm-12 mt-4">
                      <h5 className="mb-0">{testimonial.author}</h5>
                    </div>
                    <div className="col-sm-12 mt-sm-4">
                      <div className="ratting-inner">
                        <span>Rating:</span>
                        {[...Array(5)].map((star, i) => (
                          <FaStar key={i} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TestimonialTwo;
