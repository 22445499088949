import React, { useState } from "react";

// Custom component to render the accordion item
function AccordionItem({ id, isOpen, question, answer, type, onToggle }) {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className={`accordion-button ${isOpen ? "" : "collapsed"}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${type}Collapse${id}`}
          aria-expanded={isOpen}
          aria-controls={`${type}Collapse${id}`}
          onClick={() => onToggle(id, type)}
          style={{ border: "none" }}
        >
          {question}
        </button>
      </h2>
      <div
        id={`${type}Collapse${id}`}
        className={`collapse ${isOpen ? "show" : ""}`}
        aria-labelledby={`${type}Heading${id}`}
        data-bs-parent={`#accordionExample${
          type.charAt(0).toUpperCase() + type.slice(1)
        }`}
      >
        <div className="accordion-body">{answer}</div>
      </div>
    </div>
  );
}

// Constant array to store the data for the investor accordion items
// Constant array to store the data for the investor accordion items
const INVESTOR_ITEMS = [
  {
    id: 1,
    isOpen: false,
    question: "How can I become an investor?",
    answer:
      "You need to download the Joy Stays app from the Play store and register using your Mobile number and OTP. A scanned copy of your Pan, Aadhar and cancelled cheque/Passbook also needs to be uploaded.",
  },
  {
    id: 2,
    isOpen: false,
    question: "What is the minimum amount and tenure for investments?",
    answer:
      "The minimum amount for investments is Rs. 20000. Minimum tenure is 3 months.",
  },
  {
    id: 3,
    isOpen: false,
    question: "Is my investment secure? Can my investment value go down?",
    answer:
      "The investments are completely secure. We offer an MG (Minimum Guarantee) on all the investments made. All the details with the MG and payout dates are mentioned in an agreement which would be signed by both the parties.",
  },
  // Additional Investor FAQs
  {
    id: 4,
    isOpen: false,
    question: "How often do I receive payouts?",
    answer:
      "Payouts are processed on a monthly basis. You will receive your returns directly to your registered bank account.",
  },
];

// Constant array to store the data for the hotel accordion items
const HOTEL_ITEMS = [
  {
    id: 1,
    isOpen: false,
    question: "Which Hotels are eligible for on-boarding with Joy Stays?",
    answer:
      "All 2-3-4 Star hotels at all the Major travel and leisure destinations of India are eligible. The hotels should either be owned or on long lease with at least 3 years of lease remaining on the day of on-boarding.",
  },
  {
    id: 2,
    isOpen: false,
    question: "What are the documents required?",
    answer:
      "Ownership/Lease documents, GST certificate, Pan card, Aadhar card, Cancelled Cheque.",
  },
  {
    id: 3,
    isOpen: false,
    question:
      "What is the number of rooms and dates for which the deal can be done?",
    answer:
      "We can start with 10% of the available rooms. The dates can differ based on locations and seasonality.",
  },
  // Additional Hotel FAQs
  {
    id: 4,
    isOpen: false,
    question: "How does the booking process work?",
    answer:
      "Once your hotel is onboarded, users can book rooms through the Joy Stays app or website. The booking details will be sent to your hotel, and you can manage reservations through your hotel dashboard.",
  },
];

const ContactAreaTwo = () => {
  const [investorAccordionItems, setInvestorAccordionItems] =
    useState(INVESTOR_ITEMS);

  const [hotelAccordionItems, setHotelAccordionItems] = useState(HOTEL_ITEMS);

  const handleAccordionToggle = (id, type) => {
    switch (type) {
      case "investor":
        setInvestorAccordionItems((prevItems) =>
          prevItems.map((item) =>
            item.id === id ? { ...item, isOpen: !item.isOpen } : item
          )
        );
        break;
      case "hotel":
        setHotelAccordionItems((prevItems) =>
          prevItems.map((item) =>
            item.id === id ? { ...item, isOpen: !item.isOpen } : item
          )
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="contact-area">
        <div className="container" style={{ paddingTop: "100px" }}>
          <div className="contact-inner-1">
            <div className="row">
              <div
                className="col-lg-8"
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <div
                  className="fixed-background d-none d-lg-block" // Hide on small screens
                  style={{
                    backgroundImage: "url(assets/img/bg/FAQ.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    margin: "170px",
                    position: "fixed",
                    padding: "180px",
                    top: 0,
                    left: 0,
                    zIndex: -1,
                  }}
                />
              </div>
              <div
                className="col-lg-4 "
                data-aos="fade-left"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <div
                  className="section-title mb-0"
                  style={{ maxHeight: "500px", overflowY: "scroll" }}
                >
                  <h6 className="sub-title">FAQs</h6>
                  <div className="d-lg-none">
                    <img
                      className="w-100 mb-3"
                      src="assets/img/bg/FAQ.png"
                      alt="img"
                    />
                  </div>
                  <h3 className="hotel-title">Hoteller FAQs</h3>
                  {/* Display image above the accordion on small screens */}
                  <div
                    className="accordion accordion-inner style-3 accordion-icon-left mt-3"
                    id="accordionExampleHotel"
                  >
                    {hotelAccordionItems.map((item) => (
                      <AccordionItem
                        key={item.id}
                        type="hotel"
                        onToggle={handleAccordionToggle}
                        {...item}
                      />
                    ))}
                  </div>
                  <h3 className="investor-title">Investor FAQs</h3>
                  <div
                    className="accordion accordion-inner style-3 accordion-icon-left mt-3"
                    id="accordionExampleInvestor"
                  >
                    {investorAccordionItems.map((item) => (
                      <AccordionItem
                        key={item.id}
                        type="investor"
                        onToggle={handleAccordionToggle}
                        {...item}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactAreaTwo;
