import React from "react";
import {
  FaChevronRight,
  FaEnvelope,
  FaInstagram,
  FaLinkedin,
  FaPhoneAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterTwo = () => {
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className="footer-area footer-area-2 bg-gray mt-0 pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">{/* Logo here */}</div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Company</h4>
                <ul>
                  <li>
                    <Link to="/About">
                      <FaChevronRight /> About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/Contact">
                      <FaChevronRight /> Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicy">
                      <FaChevronRight /> Privacy Policy{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ToS">
                      <FaChevronRight /> Terms & Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className='col-lg-3 col-md-6 ps-xl-5'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Pay links</h4>
                <ul>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> Credit industrys
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> Reasearch sector
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> Finance Sector{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> Credit industrys
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> Reasearch sector
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> Finance Sector
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">Contact us</h4>
                <div className="widget widget_contact">
                  <ul className="details">
                    <li>
                      {/* <FaMapMarkedAlt />
                      4517 Washington Ave. Manchester, Kentucky 39495 */}
                    </li>
                    <li className="mt-3">
                      <FaPhoneAlt /> +91 9082269941
                    </li>
                    <li className="mt-2">
                      <FaEnvelope /> info@Joy-stays.com
                    </li>
                  </ul>
                  <ul className="social-media mt-4">
                    <li>
                      <a href="https://in.linkedin.com/company/Joy-stays">
                        <FaLinkedin />
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        <FaTwitter />
                      </a>
                    </li> */}
                    <li>
                      <a href="https://www.instagram.com/Joy.stays/">
                        <FaInstagram />
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        <FaYoutube />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>
                  © JOY STAYS (A Unit of JOX Private Limited) {new Date().getFullYear()} | All Rights Reserved
                </p>{" "}
              </div>
              {/* <div className="col-md-6 text-lg-end">
                <a href="#">Trams &amp; Condition</a>
                <a href="#">Privacy Policy</a>
                <a href="#">Contact Us</a>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterTwo;
