import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
const AboutAreaTwo = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dynamicStyles = {
    imageLarge: {
      height: windowWidth <= 768 ? "auto" : "77vh",
      width: "auto",
      resize: "contain",
      marginLeft: windowWidth <= 768 ? "-1rem" : "auto",
      marginTop: windowWidth <= 768 ? "-50%" : "auto",

    },
  };
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      <div className="about-area pd-top-120 pd-bottom-120" style={{ fontFamily: "'Poppins', sans-serif" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mb-4 mb-lg-0 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner">
                <img
                  className="main-img"
                  src="assets/img/about/33.png"
                  alt="img"
                  style={dynamicStyles.imageLarge}
                />
              </div>
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h6 className="sub-title">Features</h6>
                <h2 className="title">JOYSTAYS - The Premier Hotel Trading App in India</h2>
                <p className="content mb-4">Why Choose Us?</p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <li>
                        <FaCheckCircle /> Transparent & Simple
                      </li>
                      <li>
                        <FaCheckCircle /> Easy & Quick Buybacks
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <li>
                        <FaCheckCircle /> Realtime Portfolio 
                      </li>
                      <li>
                        <FaCheckCircle />  Maximise Profits
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="content" style={{ fontFamily: "'Poppins', sans-serif" }}>
                  Joy Stays is not just a platform; it's a mission. Our
                  enthusiastic and dynamic team is dedicated to creating value
                  for hotel partners, enhancing facilities, and delivering
                  delightful experiences to guests nationwide. Through strategic
                  expansion plans, we aim to redefine the hospitality landscape,
                  one room at a time.
                </p>
                <Link className="btn btn-border-base" to="/about" style={{ fontFamily: "'Poppins', sans-serif" }}>
                  About Us <FaPlus />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default AboutAreaTwo;
