// BannerTwo.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Login from './Dashboard/login';

const BannerTwo = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showLogin, setShowLogin] = useState(false);
  const [route, setRoute] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleRouteChange = (newRoute) => {
    setRoute(newRoute);
    setShowLogin(true);
  };

  const openAppStore = (appType) => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const iosPrefix = 'https://apps.apple.com/in/app/';
    const androidPrefix = 'https://play.google.com/store/apps/details?id=';

    if (appType === 'hotel') {
      if (isAndroid) {
        window.open(`${androidPrefix}com.shawezz.joyStayHotel&pcampaignid=web_share`, '_blank');
      } else {
        window.open(`${iosPrefix}joy-stays-hotels/id6475736341`, '_blank');
      }
    } else if (appType === 'investor') {
      if (isAndroid) {
        window.open(`${androidPrefix}com.shawezz.joyStayInvestor&pcampaignid=web_share`, '_blank');
      } else {
        window.open(`${iosPrefix}joy-stays-investor/id6475810491`, '_blank');
      }
    }
  };

  const dynamicStyles = {
    title: {
      fontSize: windowWidth <= 768 ? '3.5rem' : '85px',
      fontFamily: "'Poppins', sans-serif",
      fontStyle: 'normal !important',
      marginLeft: windowWidth <= 768 ? 'auto' : '10vh',
      marginTop: windowWidth <= 768 ? '5vh' : '10vh',
      whiteSpace: windowWidth <= 768 ? 'nowrap' : 'normal',
      overflow: windowWidth <= 768 ? 'hidden' : 'visible',
      textOverflow: windowWidth <= 768 ? 'ellipsis' : 'clip',
      textAlign: windowWidth <= 768 ? 'center' : 'left',
    },
    content: {
      fontSize: windowWidth <= 768 ? '1.25rem' : '40px',
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '500',
      fontStyle: 'normal !important',
      marginLeft: windowWidth <= 768 ? 'auto' : '10vh',
      marginTop: windowWidth <= 768 ? '-1.5rem' : '0',
      textAlign: windowWidth <= 768 ? 'center' : 'left',
    },
    btnContainer: {
      textAlign: windowWidth <= 768 ? 'center' : 'left',
      display: windowWidth <= 768 ? 'block' : 'inline-block',
      marginLeft: windowWidth <= 768 ? 'auto' : 'auto',
    },
    btn: {
      marginLeft: windowWidth <= 768 ? '40px' : '5rem',
      marginRight: windowWidth <= 768 ? '40px' : '1vh',
      marginBottom: windowWidth <= 768 ? '1vh' : '1vh',
      textAlign: windowWidth <= 768 ? 'center' : 'left',
      display: windowWidth <= 768 ? 'block' : 'inline-block',
    },
    secondBtn: {
      marginLeft: windowWidth <= 768 ? '40px' : '1rem',
      marginRight: windowWidth <= 768 ? '40px' : '1rem',
      marginBottom: windowWidth <= 768 ? '1vh' : '1vh',
      display: windowWidth <= 768 ? 'block' : 'inline-block',
    },
    imageSmall: {
      height: windowWidth <= 768 ? '15vh' : '25vh',
      width: 'auto',
      resize: 'contain',
      marginLeft: windowWidth <= 768 ? '3rem' : 'auto',
      marginBottom: windowWidth <= 768 ? '-5vh' : 'auto',
    },
    imageLarge: {
      height: windowWidth <= 768 ? 'auto' : '77vh',
      width: 'auto',
      resize: 'contain',
      marginLeft: windowWidth <= 768 ? '-4rem' : 'auto',
      marginTop: windowWidth <= 768 ? '-50%' : 'auto',
    },
    bg: {
      height: windowWidth <= 768 ? 'auto' : '77vh',
      width: 'auto',
      resize: 'contain',
      marginLeft: windowWidth <= 768 ? '-1rem' : 'auto',
      marginTop: windowWidth <= 768 ? '-20%' : 'auto',
    },
    img5: {
      height: windowWidth <= 768 ? '7vh' : 'auto',
      width: 'auto',
      resize: 'contain',
      marginRight: windowWidth <= 768 ? '10vh' : 'auto',
      marginTop: windowWidth <= 768 ? '-75%' : 'auto',
    },
    Arrow: {
      height: windowWidth <= 768 ? '5vh' : 'auto',
      width: 'auto',
      resize: 'contain',
      marginLeft: windowWidth <= 768 ? 'auto' : '-3rem',
      marginTop: windowWidth <= 768 ? '-50%' : 'auto',
    },
  };

  return (
    <>
      {showLogin ? (
        <Login route={route} />
      ) : (
        <div
          className="banner-area bg-relative banner-area-2 bg-cover"
          style={{ backgroundImage: 'url("./assets/img/bg/8.png")' }}
        >
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 col-md-9 "
                data-aos="fade-left"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <div className="banner-thumb-2 mt-4 mt-lg-0">
                  <img
                    className="banner-animate-img banner-animate-img-4"
                    src="assets/img/banner-2/3.png"
                    alt="img"
                    style={dynamicStyles.bg}
                  />
                  <div className="main-img-wrap">
                    <img
                      className="banner-animate-img banner-animate-img-1 left_image_bounce"
                      src="assets/img/banner-2/4.png"
                      alt="img"
                      style={dynamicStyles.Arrow}
                    />
                    <img
                      className="banner-animate-img banner-animate-img-2 left_image_bounce"
                      src="assets/img/banner-2/5.png"
                      alt="img"
                      style={dynamicStyles.img5}
                    />
                    <img
                      className="banner-animate-img banner-animate-img-3 top_image_bounce"
                      src="assets/img/banner-2/2.png"
                      alt="img"
                      style={dynamicStyles.imageSmall}
                    />
                    <img
                      className="main-img"
                      src="assets/img/banner-2/1.png"
                      alt="img"
                      style={dynamicStyles.imageLarge} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="banner-inner pe-xl-5">
                  <h1
                    className="title"
                    data-aos="fade-right"
                    data-aos-delay="200"
                    data-aos-duration="1500"
                    style={dynamicStyles.title}
                  >
                    <span>JOY </span>STAYS <p style={{ fontSize: '20px' }}>A Unit of JOX Private Limited</p>
                  </h1>
                  <h4
                    className="content pe-xl-5 "
                    data-aos="fade-right"
                    data-aos-delay="250"
                    data-aos-duration="1500"
                    style={dynamicStyles.content}
                  >
                    India's #1 Hotel Trading Platform
                  </h4>
                  <div style={dynamicStyles.btnContainer}>
                    <button
                      className="btn btn-border-base"
                      data-aos="fade-right"
                      data-aos-delay="300"
                      data-aos-duration="1500"
                      onClick={() => openAppStore('hotel')}
                      style={dynamicStyles.btn}
                    >
                      Hotel
                    </button>
                    <button
                      className="btn btn-border-base"
                      data-aos="fade-right"
                      data-aos-delay="1000"
                      data-aos-duration="1500"
                      onClick={() => openAppStore('investor')}
                      style={dynamicStyles.secondBtn}
                    >
                      Investor
                    </button>
                  </div>

                  <div
                    className="d-inline-block align-self-center "
                    data-aos="fade-right"
                    data-aos-delay="350"
                    data-aos-duration="1500"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BannerTwo;