import React from "react";

const WorkProcessTwo = () => {
  const imageContainerStyle = {
    height: "80%",
    width: "auto",
  };

  return (
    <>
      {/*==================== Work Process Two start ====================*/}
      <div className="work-process-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">Hotel App</h6>
            <h2 className="title">
              India's <span>Premier</span> Hotel Trading Platform
            </h2>
          </div>
          <div className="row">
            {/* Use col-md-6 for medium screens and col-12 for smaller screens */}
            <div className="col-lg-3 col-md-6 col-6">
              <div
                className="single-work-process-inner"
                style={imageContainerStyle}
              >
                <div className="thumb mb-3">
                  <img src="assets/img/icon/1H.png" alt="img" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div
                className="single-work-process-inner"
                style={imageContainerStyle}
              >
                <div className="thumb mb-3">
                  <img src="assets/img/icon/2h.png" alt="img" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div
                className="single-work-process-inner"
                style={imageContainerStyle}
              >
                <div className="thumb mb-3">
                  <img src="assets/img/icon/3h.png" alt="img" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div
                className="single-work-process-inner"
                style={imageContainerStyle}
              >
                <div className="thumb mb-3">
                  <img src="assets/img/icon/4h.png" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process Two end ====================*/}
      {/*==================== Work Process Three start ====================*/}
      <div className="work-process-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">Investor App</h6>
            <h2 className="title">
              India's <span>#1</span> Hotel Investing Platform
            </h2>
          </div>
          <div className="row">
            {/* Use col-md-6 for medium screens and col-12 for smaller screens */}
            <div className="col-lg-3 col-md-6 col-6">
              <div
                className="single-work-process-inner"
                style={imageContainerStyle}
              >
                <div className="thumb mb-3">
                  <img src="assets/img/icon/1I.png" alt="img" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div
                className="single-work-process-inner"
                style={imageContainerStyle}
              >
                <div className="thumb mb-3">
                  <img
                    src="assets/img/icon/2i.png"
                    alt="img"
                    style={{ padding: "-5rem" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div
                className="single-work-process-inner"
                style={imageContainerStyle}
              >
                <div className="thumb mb-3">
                  <img src="assets/img/icon/3i.png" alt="img" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div
                className="single-work-process-inner"
                style={imageContainerStyle}
              >
                <div className="thumb mb-3">
                  <img src="assets/img/icon/4i.png" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process Three end ====================*/}
    </>
  );
};

export default WorkProcessTwo;
