import React, { useEffect, useState } from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";

const CounterAreaTwo = () => {
  // Define your counters here with all the data they need
  const counters = [
    { end: 150, suffix: '+', text: 'Hotels', icon: 'Hotel.png' },
    { end: 550, suffix: '+', text: 'Investors', icon: 'Investor.png' },
    { end: 1, suffix: 'k+', text: 'Room Count', icon: 'Bed.png' },
    { end: 10, suffix: '+', text: 'Cities', icon: 'city.png' },
  ];

  const [columnSize, setColumnSize] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setColumnSize(windowWidth >= 768 ? 4 : 2); // Set to 4 columns for large screens, 2 columns for small screens
    };

    // Initial call to setColumnSize based on window width
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const counterStyle = {
    padding: '2rem 1rem',
    display: 'flex',
    alignItems: 'center',
  };

  const imageStyle = {
    flex: '0 0 60px',
    marginRight: '1rem',
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${columnSize === 4 ? '250px' : '150px'}, 1fr))`,
    gap: '20px',
  };

  return (
    <div className="counter-area bg-relative bg-cover pd-top-110 pd-bottom-100" style={{ backgroundImage: 'url("./assets/img/bg/10.png")' }}>
      <div className="container pd-bottom-90">
        <div className="row" style={gridStyle}>
          {counters.map((counter, i) => (
            <div key={i} className={`col-md-6 col-lg-${columnSize}`} data-aos="fade-up" data-aos-delay={`${100 + i * 50}`} data-aos-duration="1500" style={counterStyle}>
              <div className="single-counter-inner media">
                <div className="thumb media-left" style={imageStyle}>
                  <img src={`assets/img/icon/${counter.icon}`} alt={counter.text} />
                </div>
                <div className="media-body">
                  <h4 className="mb-1">
                    <TrackVisibility once>
                      {({ isVisible }) => isVisible && <span className="counter"><CountUp delay={0} start={0} end={counter.end} />{counter.suffix}</span>}
                    </TrackVisibility>
                  </h4>
                  <p className="mb-0">{counter.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CounterAreaTwo;
